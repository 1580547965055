* {
	font-family: "Open Sans";
	padding: 0;
	margin: 0;
}

body {
	background-color: #fafafa;
}

.App {
	font-family: "Open Sans";
	/* text-align: center; */
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	margin: 24px auto 0px auto;
	width: 380px;
	padding: 24px 24px;
	background: #ffffff;
	box-shadow: 0px 15px 60px -10px rgba(16, 25, 50, 0.05);
	border-radius: 16px;
	gap: 24px;
}

h1 {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
	text-align: left;
	color: #141b30;
}

p {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-align: left;
	color: #718096;
}

label {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-align: left;
	color: #141b30;
}

.icons {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 4px;
}

.input {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;
}

.all-input {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
}

input {
	font-family: "Open Sans";
	width: 343px;
	height: 56px;
	font-size: 16px;
	line-height: 24px;
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid #e2e8f0;
	border-radius: 8px;
	padding: 16px 16px;
	transition: 500ms;
}

input:focus {
	outline: none !important;
	border: 1px solid #dc5341;
	transition: 500ms;
}

.all-items {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
	gap: 24px;
	background: #fafafa;
	border-radius: 16px;
	flex: none;
	order: 1;
	flex-grow: 0;
}

.App-header {
	/* Auto layout */
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
}

.total {
	font-family: "Open Sans";
	font-size: 20px;
	line-height: 32px;
	font-weight: 500;
	box-sizing: border-box;
	border: 1px solid #e2e8f0;
	border-radius: 8px;
	padding: 16px 16px;
	width: 343px;
	margin-top: 8px;
	color: #dc5341;
}

.totallabel {
	font-family: "Open Sans";
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	color: #dc5341;
}

.copyearnings:hover {
	cursor: pointer;
}
.message {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px 24px;

	width: 327px;
	border-radius: 8px;
	background-color: #43aa8b;
	margin: 16px auto auto auto;
	color: white;
	position: fixed;
	top: 0;
	z-index: 1000;
	font-family: "Open Sans";
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	animation: slideDown 0.5s forwards, fadeOut 0.5s 3.5s forwards;
}

@keyframes slideDown {
	from {
		top: -50px;
	}
	to {
		top: 0;
	}
}

@keyframes fadeOut {
	from {
		top: 0;
	}
	to {
		top: -80px;
	}
}

.footer {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 18px;
	text-align: left;
	color: #718096;
}

a {
	color: #dc5341;
	text-decoration: none;
}

button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px 24px;
	gap: 8px;

	width: 343px;

	background: #dc5341;
	border-radius: 8px;
	border: none;

	font-family: "Open Sans";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
	margin-top: 24px;
	transition: 500ms;
}

button:hover {
	transition: 500ms;
	opacity: 85%;
}
